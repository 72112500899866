
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$gray-dark: #666;
$black: black;
$blue: $color-primary-1-day;
$light-red: red;
$red: red;
$bg-hovered: #888;

.bus__input {
  width: 100%;
  // height: 60px;
  // padding: 0 0 0 20px;
  font-family: 'Basis Grotesque Pro', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: $black;
  background: #fff;
  // border: 1px solid $gray-light;
  // border-radius: 4px;
  transition: border-color 0.2s ease-in-out;
  &::placeholder {
    color: $blue;
  }
}
.input--invalid {
  border-color: $light-red;
}

.base-user-search {
  position: relative;
  z-index: 4;
}
.base-user-search--open {
  z-index: 5;
}

.select__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 2;
  width: 100%;
  max-height: 180px;
  overflow: hidden;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
  .select--open & {
    max-height: 300px;
  }
}
.select__list-inner {
  width: 100%;
  height: auto;
  max-height: 180px;
  overflow: auto;
  .select--open & {
    max-height: 300px;
  }
}
.select__list-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  line-height: normal;
  padding: 8px 16px;
  color: #515a6e;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: #f3f3f3;
  }
}

.bus__user {
  padding: 5px 0 5px 20px;
}
.bus__user-name {
  display: inline-block;
  margin-right: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}
.bus__user-additional-info {
  font-size: 12px;
  line-height: 16px;
  color: $gray;

  span + span {
    &::before {
      content: '|';
    }
  }
}

.bus__user-position {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
}
.bus__user-email {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $gray;
}
.bus__user-fired {
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $red;
}
.bus__icon {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  img {
    display: inline-block;
    width: 100%;
    height: auto;
  }
}

.bus__label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;

  .input--hidden & {
    display: none;
  }
}
.bus__label--animated {
  position: absolute;
  top: 22px;
  left: 21px;
  margin-bottom: 0;
  font-size: 16px;
  color: $gray;
  pointer-events: none;
  transition: top 0.2s, font-size 0.2s;
}
.bus--animated-label {
  .bus__input {
    padding-top: 10px;
  }
}
.bus__input:focus ~ .bus__label--animated,
.bus__label--animated.bus__label--filled,
.bus__input:required:not(:invalid) ~ .bus__label--animated {
  top: 4px;
  font-size: 12px;
}

.bus--disabled {
  cursor: not-allowed;
  .bus__input {
    background-color: $gray;
  }
  .bus__label {
    color: white;
  }
}

.bus__input {
  font-size: 16px;
  background-color: $bg-gray;
  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}
