
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$base-margin: 20px;
.destination-choice__title {
  text-align: left;
}
.destination-choice__item {
  position: relative;
  display: block;
  padding-bottom: $base-margin;
  &:first-child {
    z-index: 4;
  }
  &:nth-child(2) {
    z-index: 3;
  }
  &:nth-child(3) {
    z-index: 2;
  }
  &:nth-child(4) {
    z-index: 1;
  }
}
.destination-choice__actions {
  display: flex;
  justify-content: space-between;
}
